import Men from "../Components/Men/Men";
import Navbar from "../Components/Navbar/Navbar";

function MenCatalogue() {
	return (
		<>
			<Navbar />
			<Men />
		</>
	);
}

export default MenCatalogue;
