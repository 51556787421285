import ContactSection from "../Components/ContactSection/ContactSection";
import Navbar from "../Components/Navbar/Navbar";

function Contact() {
	return (
		<>
			<Navbar />
			<ContactSection />
		</>
	);
}

export default Contact;
