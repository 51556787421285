import Navbar from "../Components/Navbar/Navbar";
import About from "../Components/About/About";

function Home() {
	return (
		<>
			<Navbar />
			<About />
		</>
	);
}

export default Home;
