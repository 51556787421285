import Women from "../Components/Women/Women";
import Navbar from "../Components/Navbar/Navbar";

function WomenCatalogue() {
	return (
		<>
			<Navbar />
			<Women />
		</>
	);
}

export default WomenCatalogue;
